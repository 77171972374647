<template>
  <b-card v-if="contractStatus !== 0 && contractData" class="h-100">
    <b-col class="mt-1 mb-1 content-wrapper">
      <div class="w-100">
        <label>{{ $i18n.t("settings.download-and-sign-contract") }}</label>
      </div>
      <b-button variant="outline-primary" size="sm" class="mt-2" @click="downloadFile">
        <div class="d-flex flex-col">
          <feather-icon icon="FileTextIcon" class="mr-50" />
          <span class="align-middle">
            {{ $i18n.t("settings.download-contract-pdf") }}
          </span>
        </div>
      </b-button>
      <div v-if="contractData" class="upload-wrapper">
        <label>{{ $i18n.t("settings.last-uploaded-in") }}</label>
        <span v-if="contractData.adminLastUploadedAt" class="font-weight-bold text-primary">
          {{ contractData.adminLastUploadedAt }}
        </span>
      </div>
    </b-col>
    <b-col>
      <b-form-group :label="$i18n.t('settings.upload-your-contract')" label-for="attachement">
        <b-form-file
          v-model="contractFile"
          size="sm"
          placeholder="Choose a file or drop it here..."
          drop-placeholder="Drop file here..."
          accept="application/pdf"
          no-drop
        />
        <div v-if="contractData.vendorLastUploadedAt" class="upload-wrapper">
          <label>{{ $i18n.t("settings.last-uploaded-in") }}</label>
          <span class="font-weight-bold text-primary">
            {{ contractData.vendorLastUploadedAt }}
          </span>
        </div>
      </b-form-group>
    </b-col>
    <b-col class="d-flex flex-row align-items-center mt-2 mr-1">
      <b-button variant="primary" size="sm" @click="uploadFile">
        {{ $i18n.t("settings.submit") }}
      </b-button>
    </b-col>
  </b-card>
  <b-card v-else>
    <p>{{ $i18n.t("settings.not-uploaded") }}.</p>
  </b-card>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import { BCol, BCard, BFormFile, BFormGroup, BButton } from "bootstrap-vue";
import { mapState, mapActions } from "vuex";
import Swal from "sweetalert2";

const FileSaver = require("file-saver");

export default {
  components: {
    BCol,
    BCard,
    BFormFile,
    BFormGroup,
    BButton,
  },

  props: {
    generalData: {
      type: Object,

      default: () => {},
    },
  },

  data() {
    return {
      contractFile: null,
      received: true,
    };
  },
  computed: {
    ...mapState("accountSettings", ["contractStatus", "contractData"]),
  },
  methods: {
    ...mapActions("accountSettings", ["userData", "uploadContract", "getContract"]),
    downloadFile() {
      FileSaver.saveAs(this.contractData.url, "contract");
    },
    async uploadFile() {
      if (this.contractFile === null) {
        Swal.fire({
          title: "Error!",
          text: "Upload contract first!",
          icon: "error",
          confirmButtonText: "Okay",
        });
      } else {
        try {
          const formData = new FormData();
          formData.append("contractAttachment", this.contractFile);
          await this.uploadContract(formData);
          this.getContract();
        } catch (err) {
          console.log(err);
        }
      }
    },
  },
};
</script>
