<template>
  <b-tabs>
    <!-- general tab -->
    <b-tab :active="isSubmitted">
      <!-- title -->
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $i18n.t("settings.general") }}</span>
      </template>
      <div>
        <div v-if="userData">
          <account-setting-general :general-data="userData" />
        </div>
      </div>

      <div class="row">
        <div v-if="areActionsAllowed" class="col-md-6 mb-1">
          <AccountContracts />
        </div>

        <div class="col-md-6 mb-1">
          <account-setting-password />
        </div>
      </div>
    </b-tab>
    <b-tab v-if="isApplicationVisible" :active="!isSubmitted">
      <template #title>
        <feather-icon icon="UserIcon" size="18" class="mr-50" />
        <span class="font-weight-bold">{{ $i18n.t("settings.application") }}</span>
      </template>
      <AccountSettingApplication />
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from "bootstrap-vue";
import { mapState, mapActions } from "vuex";
import isAllowed from "@core/utils/isAllowed";
import AccountSettingGeneral from "./AccountSettingGeneral.vue";
import AccountSettingApplication from "./AccountSettingApplication.vue";
import AccountContracts from "./AccountContracts.vue";
import AccountSettingPassword from "./AccountSettingPassword.vue";

export default {
  components: {
    BTabs,
    BTab,
    AccountSettingGeneral,
    AccountSettingPassword,
    AccountContracts,
    AccountSettingApplication,
  },
  data() {
    return {
      isSubmitted: true,
    };
  },
  computed: {
    ...mapState("accountSettings", ["userData", "userApplication"]),
    areActionsAllowed() {
      const roles = JSON.parse(localStorage.getItem("userRoles"));
      return isAllowed(["Admin"], roles);
    },
    isApplicationVisible() {
      const roles = this.userData?.data?.info.roles;
      // return roles !== null
      return true
    },
  },
  created() {
    this.getUserInfo();
    this.getUserApplication().then((response) => {
      const d = response.data.data;
      const a = response.data.data.application?.applicationStatus;
      if (!d.error && a === 0) {
        this.isSubmitted = false;
      }
    });
    if (this.areActionsAllowed) {
      this.getContractStatus();
      this.getContract();
      console.log("userData", this.userData)
    }
  },
  methods: {
    ...mapActions("accountSettings", ["getUserInfo", "getUserApplication", "getContractStatus", "getContract"]),
  },
};
</script>
