<template>
  <b-overlay :show="showLoading" rounded="sm">
    <vendor-mobile-view
      :data="data"
      :logo="logoUrl"
      :cover="coverUrl"
      :selectedCategories="selectedCategory"
    />
    <!-- {{ selectedCategory }} -->
    <b-card>
      <div class="d-flex justify-content-between">
        <div>
          {{ $i18n.t("application.status") }}:
          <span
            class="font-weight-bold font-underline"
            :style="`color: ${
              computedApplicationStatus.color
                ? computedApplicationStatus.color
                : 'blue'
            }`"
          >
            <u> {{ computedApplicationStatus.label }}</u>
          </span>
        </div>
        <b-button size="sm" variant="primary" @click="openMobileView">
          {{ $t("application.preview") }}
        </b-button>
      </div>
      <!-- form -->
      <validation-observer ref="userInfoValidation">
        <b-form v-if="userApplication" class="mt-2">
          <b-row>
            <b-col sm="4" class="d-flex flex-column mb-1">
              <b-form-group
                :label="$i18n.t('application.fullName') + ' *'"
                label-for="legalNameOrFullName"
                class="w-100 mb-0"
              >
                <validation-provider
                  #default="{ errors }"
                  name="full name or legal name"
                  rules="required"
                >
                  <b-form-input
                    v-model="data.legalNameOrFullName"
                    :readOnly="!areActionsAllowed"
                    :placeholder="$i18n.t('application.fullName') + '...'"
                    name="legalNameOrFullName"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <div class="d-flex mt-05">
                <label class="mr-05">{{
                  $i18n.t("application.is-vat-payer")
                }}</label>
                <b-form-checkbox
                  v-model="data.isVatPayer"
                  :disabled="!areActionsAllowed"
                  small
                />
              </div>
            </b-col>
            <b-col sm="4">
              <b-form-group
                :label="$i18n.t('application.contact-person-name') + ' *'"
                label-for="contactPerson"
              >
                <validation-provider
                  #default="{ errors }"
                  name="contact person"
                  rules="required"
                >
                  <b-form-input
                    v-model="data.contactPerson"
                    :readOnly="!areActionsAllowed"
                    :placeholder="$i18n.t('application.contact-person-name')"
                    name="contactPerson"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                :label="$i18n.t('application.contact-person-position') + ' *'"
                label-for="contactPersonPosition"
              >
                <validation-provider
                  #default="{ errors }"
                  name="contact person position"
                  rules="required"
                >
                  <b-form-input
                    v-model="data.contactPersonPosition"
                    :readOnly="!areActionsAllowed"
                    :placeholder="
                      $i18n.t('application.contact-person-position')
                    "
                    name="contactPersonPosition"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                :label="$i18n.t('application.contact-person-mobile') + ' *'"
                label-for="contactPersonMobile"
              >
                <validation-provider
                  #default="{ errors }"
                  name="contact person mobile"
                  rules="required"
                >
                  <b-form-input
                    v-model="data.contactPersonMobile"
                    :readOnly="!areActionsAllowed"
                    :placeholder="$i18n.t('application.contact-person-mobile')"
                    name="contactPersonMobile"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                :label="$i18n.t('application.brand-name-geo') + ' *'"
                label-for="brandNameGeo"
              >
                <validation-provider
                  #default="{ errors }"
                  name="brand name georgian"
                  rules="required"
                >
                  <b-form-input
                    v-model="data.brandNameGeo"
                    :readOnly="!areActionsAllowed"
                    :placeholder="$i18n.t('application.brand-name-geo')"
                    name="brandNameGeo"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                :label="$i18n.t('application.brand-name-eng') + ' *'"
                label-for="brandNameEng"
              >
                <validation-provider
                  #default="{ errors }"
                  name="brand name english"
                  rules="required"
                >
                  <b-form-input
                    v-model="data.brandNameEng"
                    :readOnly="!areActionsAllowed"
                    :placeholder="$i18n.t('application.brand-name-eng')"
                    name="brandNameEng"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                :label="$i18n.t('application.legal-address') + ' *'"
                label-for="legalAddress"
              >
                <validation-provider
                  #default="{ errors }"
                  name="legal address"
                  rules="required"
                >
                  <b-form-input
                    v-model="data.legalAddress"
                    :readOnly="!areActionsAllowed"
                    :placeholder="$i18n.t('application.legal-address')"
                    name="legalAddress"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                :label="$i18n.t('application.physical-address') + ' *'"
                label-for="physicalAddress"
              >
                <validation-provider
                  #default="{ errors }"
                  name="physical address"
                  rules="required"
                >
                  <b-form-input
                    v-model="data.physicalAddress"
                    :readOnly="!areActionsAllowed"
                    :placeholder="$i18n.t('application.physical-address')"
                    name="physicalAddress"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="userApplication.application.vendorType === 1" sm="4">
              <b-form-group
                :label="$i18n.t('application.director-fullName') + ' *'"
                label-for="directorFullName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="director full name"
                  rules="required"
                >
                  <b-form-input
                    v-model="data.directorFullName"
                    :readOnly="!areActionsAllowed"
                    :placeholder="$i18n.t('application.director-fullName')"
                    name="directorFullName"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="userApplication.application.vendorType === 1" sm="4">
              <b-form-group
                :label="$i18n.t('application.director-phone') + ' *'"
                label-for="directorMobile"
              >
                <validation-provider
                  #default="{ errors }"
                  name="director mobile number"
                  rules="required"
                >
                  <b-form-input
                    v-model="data.directorMobile"
                    :readOnly="!areActionsAllowed"
                    :placeholder="$i18n.t('application.director-phone')"
                    name="directorMobile"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col v-if="userApplication.application.vendorType === 1" sm="4">
              <b-form-group
                :label="$i18n.t('application.accountant-fullName')"
                label-for="accountantFullName"
              >
                <b-form-input
                  v-model="data.accountantFullName"
                  :readOnly="!areActionsAllowed"
                  :placeholder="$i18n.t('application.accountant-fullName')"
                  name="accountantFullName"
                />
              </b-form-group>
            </b-col>
            <b-col v-if="userApplication.application.vendorType === 1" sm="4">
              <b-form-group
                :label="$i18n.t('application.accountant-phone')"
                label-for="accountantMobile"
              >
                <b-form-input
                  v-model="data.accountantMobile"
                  :readOnly="!areActionsAllowed"
                  :placeholder="$i18n.t('application.accountant-fullName')"
                  name="accountantMobile"
                />
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                :label="$i18n.t('application.iban') + ' *'"
                label-for="iban"
              >
                <validation-provider
                  #default="{ errors }"
                  name="iBan"
                  rules="required"
                >
                  <b-form-input
                    v-model="data.iban"
                    :readOnly="!areActionsAllowed"
                    :placeholder="$i18n.t('application.iban')"
                    name="iban"
                    @input="() => { data.iban = data.iban.trim() }"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                :label="$i18n.t('application.other-contact-person-name') + ' *'"
                label-for="otherContactPersonFullName"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Client contact person name"
                  rules="required"
                >
                  <b-form-input
                    v-model="data.otherContactPersonFullName"
                    :readOnly="!areActionsAllowed"
                    :placeholder="
                      $i18n.t('application.other-contact-person-name')
                    "
                    name="otherContactPersonFullName"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                :label="
                  $i18n.t('application.other-contact-person-mobile') + ' *'
                "
                label-for="otherContactPersonMobile"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Client contact person phone"
                  rules="required"
                >
                  <b-form-input
                    v-model="data.otherContactPersonMobile"
                    :readOnly="!areActionsAllowed"
                    :placeholder="
                      $i18n.t('application.other-contact-person-mobile')
                    "
                    name="otherContactPersonMobile"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                :label="$i18n.t('application.warehouse-address')"
                label-for="warehouseAddress"
              >
                <b-form-input
                  v-model="data.warehouseAddress"
                  :readOnly="!areActionsAllowed"
                  :placeholder="$i18n.t('application.warehouse-address')"
                  name="warehouseAddress"
                />
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                :label="$i18n.t('application.web-page')"
                label-for="webPage"
              >
                <b-form-input
                  v-model="data.webPage"
                  :readOnly="!areActionsAllowed"
                  :placeholder="$i18n.t('application.web-page')"
                  name="webPage"
                />
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                :label="$i18n.t('application.facebook')"
                label-for="facebookLink"
              >
                <b-form-input
                  v-model="data.facebookLink"
                  :readOnly="!areActionsAllowed"
                  :placeholder="$i18n.t('application.facebook')"
                  name="facebookLink"
                />
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <b-form-group
                :label="$i18n.t('application.instagram')"
                label-for="instagramLink"
              >
                <b-form-input
                  v-model="data.instagramLink"
                  :readOnly="!areActionsAllowed"
                  :placeholder="$i18n.t('application.instagram')"
                  name="instagramLink"
                />
              </b-form-group>
            </b-col>
            <b-col sm="4" class="d-flex align-items-center">
              <b-form-group
                :label="$i18n.t('application.logo') + ' *'"
                label-for="logo"
                class="w-100"
              >
                <b-form-file
                  v-model="data.logo"
                  :disabled="!areActionsAllowed"
                  :placeholder="$i18n.t('application.logo')"
                  drop-placeholder="Drop file here..."
                  accept="image/*"
                  no-drop
                />
                <span class="text-secondary">{{
                    $i18n.t("application.profile-photo-hint")
                  }}</span>
              </b-form-group>
              <div v-if="logoUrl" class="w-25 ml-1">
                <a :href="logoUrl" target="_blank">
                  <img
                    id="app-logo"
                    :src="logoUrl"
                    label="logo"
                    class="rounded"
                  />
                </a>
              </div>
            </b-col>
            <b-col sm="4" class="d-flex align-items-center">
              <b-form-group
                :label="$i18n.t('application.cover') + ' *'"
                label-for="cover"
                class="w-100"
              >
                <b-form-file
                  v-model="data.cover"
                  :disabled="!areActionsAllowed"
                  :placeholder="$i18n.t('application.cover')"
                  drop-placeholder="Drop file here..."
                  accept="image/*"
                  no-drop
                />
                <span class="text-secondary">{{
                    $i18n.t("application.cover-photo-hint")
                  }}</span>
              </b-form-group>
              <div v-if="coverUrl" class="w-25 ml-1">
                <a :href="coverUrl" target="_blank">
                  <img
                    id="app-logo"
                    :src="coverUrl"
                    label="logo"
                    class="rounded"
                  />
                </a>
              </div>
            </b-col>
            <b-col sm="4">
              <b-form-group
                :label="$i18n.t('application.branches')"
                label-for="branches"
              >
                <div class="d-flex flex-row">
                  <b-form-input
                    v-model="tempBranchGeo"
                    :readOnly="!areActionsAllowed"
                    :placeholder="$i18n.t('application.branches-geo')"
                  />
                  <b-form-input
                    v-model="tempBranchEng"
                    :readOnly="!areActionsAllowed"
                    :placeholder="$i18n.t('application.branches-eng')"
                    class="left-margin"
                  />
                  <b-button
                    variant="primary"
                    size="sm"
                    class="left-margin"
                    @click="addBranchToBranches"
                  >
                    <feather-icon size="16" icon="PlusIcon" />
                  </b-button>
                </div>
              </b-form-group>
            </b-col>
            <b-col sm="4">
              <label for="">Selected Branches</label>
              <div class="branches">
                <b-button
                  v-for="branch in data.branches"
                  :key="branch.nameGeo + Math.random()"
                  variant="primary"
                  class="left-margin bottom-margin branch-pill"
                  size="sm"
                  pill
                  @click="areActionsAllowed && deleteBranch(branch)"
                >
                  {{ branch.nameGeo }} / {{ branch.nameEng }}
                </b-button>
              </div>
            </b-col>
            <!-- {{ selectedSegment }} -->
            <b-col sm="6">
              <label for="cat-multiselect">{{
                $i18n.t("application.segments")
              }}</label>
              <multiselect
                id="cat-multiselect"
                v-model="selectedCategory"
                :options="categories"
                :disabled="!areActionsAllowed"
                :multiple="true"
                :placeholder="$i18n.t('application.type-to-search')"
                label="label"
                track-by="label"
              >
                <span slot="noResult">
                  Oops! No elements found. Consider changing the search query.
                </span>
              </multiselect>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="6">
              <b-form-group
                :label="$i18n.t('application.description-geo')"
                label-for="logo"
              >
                <b-form-textarea
                  id="textarea-default"
                  v-model="data.descriptionGeo"
                  :readonly="!areActionsAllowed"
                  :placeholder="$i18n.t('application.description-geo')"
                  cols="3"
                />
                <span
                  v-if="data.descriptionGeo"
                  :class="data.descriptionGeo.length > 500 ? 'text-danger' : ''"
                >
                  {{ data.descriptionGeo.length }} / 500
                </span>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group
                :label="$i18n.t('application.description-eng')"
                label-for="logo"
              >
                <b-form-textarea
                  id="textarea-default"
                  v-model="data.descriptionEng"
                  :readonly="!areActionsAllowed"
                  :placeholder="$i18n.t('application.description-eng')"
                  cols="3"
                />
                <span
                  v-if="data.descriptionGeo"
                  :class="data.descriptionEng.length > 500 ? 'text-danger' : ''"
                >
                  {{ data.descriptionEng.length }} / 500
                </span>
              </b-form-group>
            </b-col>
          </b-row>
          <b-col v-if="areActionsAllowed" class="pl-0" cols="12">
            <b-button
              :disabled="disableButtonOnDescription"
              variant="primary"
              class="mt-2 mr-1"
              @click.prevent="validationForm"
            >
              {{
                status === 0
                  ? $i18n.t("registration.submit")
                  : $i18n.t("application.update")
              }}
            </b-button>
          </b-col>
        </b-form>
      </validation-observer>
    </b-card>
  </b-overlay>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BFormTextarea,
  BOverlay,
  BFormCheckbox,
  BFormFile,
  BRow,
  BCol,
  BCard,
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
// eslint-disable-next-line import/no-extraneous-dependencies
import { required, email } from "@validations";
import { mapActions, mapState } from "vuex";
import isAllowed from "@core/utils/isAllowed";
import "@riophae/vue-treeselect/dist/vue-treeselect.css";
import objectToFormData from "@/helpers/objectToFormData";
import getDifference from "@/helpers/getDifference";
import Multiselect from "vue-multiselect";
import Swal from "sweetalert2";
import VendorMobileView from "./VendorMobileView.vue";

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormFile,
    BFormCheckbox,
    BFormTextarea,
    ValidationProvider,
    ValidationObserver,
    BOverlay,
    BFormInput,
    BRow,
    BCol,
    BCard,
    Multiselect,
    VendorMobileView,
  },
  data() {
    return {
      status: null,
      logoUrl: null,
      coverUrl: null,
      selectedCategory: [],
      categories: [],
      tempBranchGeo: "",
      tempBranchEng: "",
      required,
      email,
      data: {
        legalNameOrFullName: "",
        contactPerson: "",
        contactPersonPosition: "",
        contactPersonMobile: "",
        brandNameGeo: "",
        brandNameEng: "",
        legalAddress: "",
        physicalAddress: "",
        descriptionGeo: "",
        descriptionEng: "",
        directorFullName: "",
        directorMobile: "",
        accountantFullName: "",
        accountantMobile: "",
        iban: "",
        otherContactPersonFullName: "",
        otherContactPersonMobile: "",
        warehouseAddress: "",
        webPage: "",
        facebookLink: "",
        instagramLink: "",
        logo: null,
        cover: null,
        isVatPayer: false,
        branches: [],
        newBranches: [],
        segments: [],
      },
    };
  },
  computed: {
    ...mapState("accountSettings", [
      "userApplication",
      "userData",
      "showLoading",
    ]),
    ...mapState("vendorRegistration", ["productCategories"]),
    areActionsAllowed() {
      const roles = this.userData?.data?.info.roles;
      // const roles = JSON.parse(localStorage.getItem("userRoles"));
      if (roles === null) return true;
      return isAllowed(["Admin"], roles);
    },
    disableButtonOnDescription() {
      if (!this.data.descriptionGeo) return false;
      return (
        this.data.descriptionGeo.length > 500 ||
        this.data.descriptionEng.length > 500
      );
    },
    computedApplicationStatus() {
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.status = this.userApplication?.application.applicationStatus;
      if (!this.status) {
        return {
          label: this.$i18n.t("application.unsubmitted"),
          color: "lightblue",
        };
      }
      const applicationStatus = {
        0: {
          label: this.$i18n.t("application.unsubmitted"),
          color: "lightblue",
        },
        1: { label: this.$i18n.t("application.pending"), color: "orange" },
        2: { label: this.$i18n.t("application.rejected"), color: "red" },
        3: { label: this.$i18n.t("application.approved"), color: "green" },
        4: { label: this.$i18n.t("application.pending"), color: "orange" },
      };
      return applicationStatus[this.status];
    },
  },
  watch: {
    selectedCategory(newVal) {
      this.data.segments = newVal.map((e) => ({
        categoryId: e.categoryId,
        subCategoryId: e.subCategoryId,
      }));
      if (this.status !== 0) {
        this.data.segmentsUpdate.newVendorSegments = newVal.map((e) => ({
          categoryId: e.categoryId,
          subCategoryId: e.subCategoryId,
        }));
      }
    },
    // eslint-disable-next-line func-names
    "data.logo": function (newVal) {
      if (this.status !== 0) {
        this.data.logoUpdate = {
          isPreviousDeleted: true,
          newLogo: newVal,
        };
      }
    },
    "data.cover": function (newVal) {
      if (this.status !== 0) {
        this.data.coverUpdate = {
          isPreviousDeleted: true,
          newCover: newVal,
        };
      }
    },
    userApplication(newVal) {
      if (this.status !== 0) {
        // eslint-disable-next-line no-console
        console.log("user application ", newVal);
        this.setApplicationForEdit(newVal);
      }
      this.data.vendorType = newVal.application.vendorType;
    },
  },
  mounted() {
    this.getProductCategories({
      take: 100,
      skip: 0,
    }).then(() => {
      this.categories = []
      this.productCategories.forEach((el) => {
        const item = {
          categoryId: el.id,
          subCategoryId: el.id,
          label: `${el.label}`,
          isParent: true
        }
        this.categories.push(item)
        el.children.forEach((data) => {
          const item = {
            categoryId: data.categoryId,
            subCategoryId: data.subCategoryId,
            label: `- ${data.label}`
          }
          this.categories.push(item)
        })
      })
    });
  },
  created() {
    this.$bvModal.show("vendor-mobile-modal");
    const roles = this.userData?.data?.info.roles;
    if (this.areActionsAllowed || roles === null) {
      this.getProductCategories({
        take: 100,
        skip: 0,
      });
    }
  },
  methods: {
    ...mapActions("vendorRegistration", ["getProductCategories"]),
    ...mapActions("accountSettings", [
      "vendorApplication",
      "getUserApplication",
    ]),
    openMobileView() {
      this.$bvModal.show("vendor-mobile-modal");
    },
    setApplicationForEdit(application) {
      const app = application.application;

      this.data = {
        legalNameOrFullName: app.legalNameOrFullName,
        contactPerson: app.contactPerson,
        contactPersonPosition: app.contactPersonPosition,
        contactPersonMobile: app.contactPersonMobile,
        brandNameGeo: app.brandNameGeo,
        brandNameEng: app.brandNameEng,
        legalAddress: app.legalAddress,
        physicalAddress: app.physicalAddress,
        descriptionEng: app.descriptionEng,
        descriptionGeo: app.descriptionGeo,
        directorFullName: app.directorFullName,
        directorMobile: app.directorMobile,
        accountantFullName: app.accountantFullName,
        accountantMobile: app.accountantMobile,
        iban: app.iban,
        otherContactPersonFullName: app.otherContactPersonFullName,
        otherContactPersonMobile: app.otherContactPersonMobile,
        warehouseAddress: app.warehouseAddress,
        webPage: app.webPage,
        facebookLink: app.facebookLink,
        instagramLink: app.instagramLink,
        logo: null,
        cover: null,
        isVatPayer: app.isVatPayer,
        branches: app.branches,
        branchUpdate: {
          deletedBranches: [],
          newBranches: [],
        },
        logoUpdate: {
          isPreviousDeleted: false,
          newLogo: null,
        },
        coverUpdate: {
          isPreviousDeleted: false,
          newCover: null,
        },
        segmentsUpdate: {
          deletedVendorSegments: [],
          newVendorSegments: [],
        },
      };
      this.logoUrl = app.logoUrl;
      this.coverUrl = app.coverUrl;

      this.selectedCategory = app.segments.map((product) => ({
        categoryId: product.productCategoryId,
        subCategoryId: product.productSubCategoryId,
        label: product.subCategoryNameGeo ? `- ${product.subCategoryNameGeo} / ${product.subCategoryNameEng}` : `${product.categoryNameGeo} / ${product.categoryNameEng}`,
      }));
      this.existingBranhces = app.segments.map((product) => ({
        id: product.id,
        categoryId: product.productCategoryId,
        subCategoryId: product.productSubCategoryId,
        label: product.subCategoryNameGeo ? `- ${product.subCategoryNameGeo} / ${product.subCategoryNameEng}` : `${product.categoryNameGeo} / ${product.categoryNameEng}`,
      }));
    },

    validateIban(iban) {
      iban = iban.trim()
      console.log("shemo", iban)
    },

    addBranchToBranches() {
      if (this.tempBranchEng === "" || this.tempBranchGeo === "") return;
      const branch = {
        nameGeo: this.tempBranchGeo,
        nameEng: this.tempBranchEng,
      };

      this.data.branches.push(branch);

      // on edit push to branchupdate;
      if (this.status !== 0) {
        this.data.branchUpdate.newBranches.push(branch);
      }

      this.tempBranchGeo = "";
      this.tempBranchEng = "";
    },
    deleteBranch(branch) {
      const indexOfObject = this.data.branches.findIndex(
        (e) => e.nameGeo === branch.nameGeo && e.nameEng === branch.nameEng
      );
      this.data.branches.splice(indexOfObject, 1);

      // on edit push to branchupdate
      if (this.status !== 0 && branch.id) {
        this.data.branchUpdate.deletedBranches.push(branch.id);
      }
    },
    handleDeletedSegments() {
      const deletedBranches = getDifference(
        this.existingBranhces,
        this.selectedCategory
      );
      const addedBranches = getDifference(
        this.selectedCategory,
        this.existingBranhces
      );

      this.data.segmentsUpdate = {
        deletedVendorSegments: deletedBranches.length
          ? deletedBranches.map((e) => e.id)
          : null,
        newVendorSegments: addedBranches.length
          ? addedBranches.map((e) => ({
              categoryId: e.categoryId,
              subCategoryId: e.subCategoryId,
              // eslint-disable-next-line no-mixed-spaces-and-tabs
            }))
          : null,
      };
    },
    async validationForm() {
      if (this.status !== 0) {
        this.handleDeletedSegments();
        this.data.branches = [];
        this.data.segments = [];
      }
      const formData = objectToFormData(this.data);

      const success = await this.$refs.userInfoValidation.validate();

      if (success) {
        try {
          await this.vendorApplication({
            formData,
            create: this.status === 0,
          });
          this.getUserApplication();
          Swal.fire({
            position: "center",
            icon: "success",
            text: this.$i18n.t("application.submit-success"),
          });
        } catch (e) {
          // eslint-disable-next-line no-console
          console.log(e);
        }
      }
    },
    searchCategories(text) {
      // this.categories = this.productCategories.filter(el => el.label.includes(text))
      // console.log("searchCategories", text, this.categories)
    }
  },
};
</script>
<style lang="scss" scoped>
.left-margin {
  margin-left: 0.5rem;
}

.bottom-margin {
  margin-bottom: 0.5rem;
}

.branch-pill:hover {
  color: rgb(155, 0, 0) !important;
  text-decoration: line-through;
}

#app-logo {
  width: 50px;
  height: auto;
}
</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
