<template>
  <b-modal id="vendor-mobile-modal" ref="vendor-mobile-modal" size="sm" ok-only>
    <div class="container">
      <div class="top-container" :style="computedCoverStyle">
        <div class="top">
          <div>
            <feather-icon
              icon="ChevronLeftIcon"
              size="30"
              style="stroke: white"
            />
          </div>

          <div class="x">
            <feather-icon icon="XIcon" size="20" style="stroke: white" />
          </div>
        </div>
      </div>
      <div class="bottom-wrapper">
        <div class="bottom-starter" :style="computedBottomContainerStyle">
          <div class="top-sides">
<!--            <img :src="require('@/assets/images/bg/emo_rating.svg')" />-->
<!--            <span>4.96</span>-->
          </div>
          <div class="logo-container">
            <img :src="logo" class="logo" />
          </div>
          <div class="top-sides">
            <img
              :src="require('@/assets/images/bg/phone.svg')"
              class="ml-auto"
            />
          </div>
        </div>
        <div class="info">
          <p class="brandName">{{ brandName }}</p>
          <p class="brand-description">
            {{ brandDescription }}
          </p>
        </div>
        <div class="social">
          <img
            :src="require('@/assets/images/bg/fb.svg')"
            @click="handleSocial('fb')"
          />
          <img
            :src="require('@/assets/images/bg/Ig.svg')"
            @click="handleSocial('ig')"
          />
          <img
            :src="require('@/assets/images/bg/web.svg')"
            @click="handleSocial('web')"
          />
        </div>
        <div class="catalog">
          <div class="title">
            {{ $t("global.catalog") }}
          </div>
          <div class="rest">
            <div v-for="cat in selectedCategories" :key="cat.id" class="pill">
              {{ cat.label }}
            </div>
            <div class="products-grid">
              <div
                v-for="product in products"
                :key="product.id"
                class="product"
              >
                <img src="https://picsum.photos/160/125" />
                <p class="product-title">
                  {{
                    $i18n.locale === "ka" ? product.nameGeo : product.nameEng
                  }}
                </p>
                <p class="price">{{ product.price }}₾</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-modal>
</template>

<script>
import { BModal } from "bootstrap-vue";
import { mapActions } from "vuex";

export default {
  name: "VendorMobileView",
  components: {
    BModal,
  },
  props: {
    data: {
      type: Object,
      default: () => ({}),
    },
    logo: {
      type: String,
      default: () => ({}),
    },
    cover: {
      type: String,
      default: () => ({}),
    },
    selectedCategories: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      products: [],
    };
  },
  computed: {
    computedCoverStyle() {
      return `background-image: url(${this.cover})`;
    },
    computedBottomContainerStyle() {
      // eslint-disable-next-line global-require
      const bg = require("@/assets/images/bg/vendor.svg");
      return `background-image: url(${bg})`;
    },
    brandName() {
      return this.$i18n.locale === "ka"
        ? this.data.brandNameGeo
        : this.data.brandNameEng;
    },
    brandDescription() {
      return this.$i18n.locale === "ka"
        ? this.data.descriptionGeo
        : this.data.descriptionEng;
    },
  },
  mounted() {
    this.fetchProducts();
  },
  methods: {
    ...mapActions("products", ["getProducts"]),
    mainImage(product) {
      if (product.mainImage) return product.mainImage.url;
      if (product.productConfigurations.length === 0) return null;
      return product.productConfigurations[0].mainImage.url;
    },
    async fetchProducts() {
      const response = await this.getProducts({
        productType: "products",
        skip: 0,
        take: 4,
      });
      this.products = response.data.data.products;
    },
    handleSocial(type) {
      let link = "";
      switch (type) {
        case "fb":
          link = this.data.facebookLink;
          break;
        case "ig":
          link = this.data.instagramLink;
          break;
        case "web":
          link = this.data.webPage;
          break;
        default:
          console.log(`Unsupported social media type: ${type}`);
          return;
      }
      window.open(link, "_blank");
    },
  },
};
</script>
<style scoped lang="scss">
.container {
  width: 100%;
  max-width: 374px;
}
.top-container {
  width: 100%;
  padding: 4rem 2rem;
  height: 270px;
  background-size: cover;
  background-position: center center;

  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .x {
    background: rgba(255, 255, 255, 0.3);
    border-radius: 100%;
    padding: 10px;
  }
}
.bottom-wrapper {
  margin-top: -100px;
}
.bottom-starter {
  background-position: top;
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  div {
    width: 33.333%;
  }

  .logo-container {
    background: white;
    border-radius: 100%;
    width: 114px;
    height: 114px;
    background: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transform: translateY(-35%);
    z-index: 2;
  }

  .logo {
    max-width: 70px;
    width: 100%;
    height: auto;
    max-height: 50%;
  }
  .top-sides {
    max-width: 70px;
    overflow: hidden;
    display: flex;
    // justify-content: center;
    align-items: center;
    font-weight: bold;

    span {
      margin-left: 7px;
    }
  }
}

.info {
  .brandName {
    font-size: 24px;
    font-weight: 600;
    line-height: 19.2px;
    font-family: "FiraGo";
    margin-bottom: 1rem;
    text-align: center;
    color: #39373d;
    font-variant-caps: all-small-caps;
  }
  .brand-description {
    font-size: 14px;
    font-weight: 400;
    line-height: 16.8px;
    font-family: "FiraGo";
    margin: 0;
    color: #b8b7bd;
  }
}

.social {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1rem 0;
  gap: 8px;

  img {
    cursor: pointer;
  }
}

.catalog {
  .title {
    border-top-left-radius: 24px;
    border-top-right-radius: 24px;
    box-shadow: 0px -2px 18px rgba(0, 0, 0, 0.05);
    padding: 14px 8px;
    font-size: 34px;
    font-weight: 700;
    line-height: 29px;
    // text-align: center;
    font-family: "FiraGo";
    min-height: 50px;
    color: #39373d;
    font-variant-caps: all-small-caps;
  }
  .rest {
    min-height: 100px;
    background: white;
    transform: translateY(-10px);
    padding: 16px 8px;
    overflow-x: scroll;

    .pill {
      background: #f5f5f7;
      border-radius: 10px;
      padding: 11px;
      font-size: 12px;
      font-weight: 400;
      line-height: 44.4px;
      font-family: "FiraGo";
      color: #96949c;
      display: inline;
      margin-right: 16px;
    }

    .products-grid {
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: auto;
      grid-gap: 8px;
      margin-top: 16px;

      .product {
        width: 100%;
        border-radius: 12px;
        padding: 8px;
        border: 1px solid #f5f5f7;

        img {
          width: 100%;
          height: auto;
        }

        .product-title {
          font-family: "FiraGo";
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          color: #18171b;
          margin: 10px 0;
        }

        .price {
          font-family: "FiraGo";
          font-weight: 400;
          font-size: 16px;
          line-height: 19px;
          color: #39373d;
        }
      }
    }
  }
}
</style>
