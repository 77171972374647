const getDifference = (array1, array2) =>
	array1.filter(
		(object1) =>
			!array2.some(
				(object2) =>
					object1.categoryId === object2.categoryId &&
					object1.subCategoryId === object2.subCategoryId
			)
	);

export default getDifference;
